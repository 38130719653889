<div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">

        <!-- Page Header -->
        <div class="page-header">
            <div class="row">
                <div class="col-sm-12">
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/core/dashboard/admin-dashboard">Dashboard </a></li>
                        <li class="breadcrumb-item"><i class="feather icon-chevron-right"></i></li>
                        <li class="breadcrumb-item active">Settings</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- /Page Header -->

        <div class="settings-menu-links">
            <ul class="nav nav-tabs menu-tabs">
                <li class="nav-item ">
                    <a class="nav-link" [routerLink]="routes.settings">General Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.localizationDetails">Localization</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.paymentSettings">Payment Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.emailSettings">Email Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.socialSettings">Social Media Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.socialLinks">Social Links</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.seoSettings">SEO Settings</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.themeSettings">Theme Settings</a>
                </li>
                <li class="nav-item active">
                    <a class="nav-link" [routerLink]="routes.changePassword">Change Password</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="routes.othersSettings">Others</a>
                </li>
            </ul>
        </div>
        
        <div class="row">
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <h4 class="page-title">Change Password</h4>
                                <div class="col-12 col-md-6 col-xl-12">  
                                    <div class="form-group local-forms">
                                        <label for="Old">Old password <span class="login-danger">*</span></label>
                                        <input class="form-control" type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-6">  
                                    <div class="form-group local-forms">
                                        <label for="Old">New password <span class="login-danger">*</span></label>
                                        <input class="form-control" type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-xl-6">  
                                    <div class="form-group local-forms">
                                        <label for="confirmpassword">Confirm Password <span class="login-danger">*</span></label>
                                        <input class="form-control" type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="doctor-submit text-end">
                                        <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <!-- /Page Content -->
</div>