<div class="header">
    <div class="header-left">
        <a [routerLink]="routes.adminDashboard" class="logo"><img src="assets/img/logo.png" width="35" height="35" alt=""> <span>UTICA Admin</span></a>
    </div>
    <a (click)="toggleSideBar()" id="toggle_btn" href="javascript:void(0);"><img src="assets/img/icons/bar-icon.svg"  alt=""></a>
    <a (click)="toggleMobileSideBar()" (keyup.enter)="toggleMobileSideBar()" id="mobile_btn" class="mobile_btn float-start" tabindex="0"  ><img src="assets/img/icons/bar-icon.svg"  alt=""></a>
    <div class="top-nav-search mob-view">
        <form>
            <input type="text" class="form-control" placeholder="Chercher ici">
            <a class="btn" ><img src="assets/img/icons/search-normal.svg" alt=""></a>
        </form>
    </div>
    <ul class="nav user-menu float-end">
        <li class="nav-item dropdown has-arrow user-profile-list">
            <a href="javascript:void(0);" class="dropdown-toggle nav-link user-link" data-bs-toggle="dropdown">
                <div class="user-names">
                    <h5>Ahmed Ahmed </h5>
                    <span>Admin</span>
                </div>
                <span class="user-img"><img  src="assets/img/user-06.jpg"  alt="Admin"> </span>
            </a>
            <div class="dropdown-menu">
                <a class="dropdown-item" [routerLink]="routes.profile">Mon Profile</a>
                <a class="dropdown-item" [routerLink]="routes.editProfile">Edit Profile</a>
                <a class="dropdown-item" [routerLink]="routes.settings">Paramétres</a>
                <a class="dropdown-item" [routerLink]="routes.login">Déconnecter</a>
            </div>
        </li>
    </ul>
    <div class="dropdown mobile-user-menu float-end">
        <a href="javascript:void(0);" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-ellipsis-vertical"></i></a>
        <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" [routerLink]="routes.profile">Mon Profile</a>
            <a class="dropdown-item" [routerLink]="routes.editProfile">Edit Profile</a>
            <a class="dropdown-item" [routerLink]="routes.settings">Paramétres</a>
            <a class="dropdown-item" [routerLink]="routes.login">Déconnecter</a>
        </div>
    </div>
</div>