import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './shared/auth/auth.service';
import { HomeComponent } from './home/home.component';
import { ChangePasswordComponent } from './core/settings/change-password/change-password.component';
//import { UticaAdminComponent } from './utica-admin/utica-admin.component';

const routes: Routes = [
  {    path: '', pathMatch: 'full',        redirectTo: '/home',  },
//  { path: 'utica-admin', component: UticaAdminComponent },
  { path: 'home', component: HomeComponent },
  {    path: '',    loadChildren: () => import('./core/core.module').then((m) => m.CoreModule),  },
  {    path: '',    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),  },
  {    path: 'error',    loadChildren: () => import('./error/error500/error500.module').then((m) => m.Error500Module),  },
  {    path: 'fail',    loadChildren: () => import('./error/fail/fail.module').then((m) => m.FailModule),  },
  {    path: 'success',    loadChildren: () => import('./error/success/success.module').then((m) => m.SuccessModule),  },
  { path: 'change-password2/NTgx/**', component: ChangePasswordComponent },
  { path: '**', redirectTo: '/home' }
 // {    path: '**',    redirectTo: 'error/error404',    pathMatch: 'full',  },
//  {    path: 'access-restrited', component:Error,    canActivateChild: [AuthService]},
 // {    path: 'home',   loadChildren: () =>  import('./home/home.module').then((m) => m.HomeModule  ),  },
 // {    path: 'dashboard',   loadChildren: () =>  import('./authentication/dashboard/dashboard.module').then((m) => m.DashboardModule  ),  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }