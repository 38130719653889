import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(public translate: TranslateService) {
    translate.setDefaultLang('fr');  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.setDirection(language);
  }
  setDirection(language: string) {
    const dir = language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.dir = dir;
  }
}
